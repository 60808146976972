@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Shrikhand');

$primary: #ed6b5d;/* MAIN COLOR */
$secondary: #fadceb; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            font-family: 'Open Sans Condensed', sans-serif;
            font-size: 24px;
            text-align: center;
            margin-top: 42px;
            display: flex;
            margin-left: 4px;
            align-items: center;
            background: $primary;
            color: $wht;
            @media (max-width: 767px) {
                background: transparent;
                color: $primary;
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: $primary;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $secondary;
                color: $primary;
                @media (max-width: 1024px) {
                    background: $primary;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 0.5em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 350px;
    padding: 0.5em;
}

@media(max-width: 990px) {
    .logo {
        max-width: 305px;
        margin-top: 10px;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 225px;
    }
    .navbar-toggle {
        margin-top: 30px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 205px;
        margin-top: 0;
    }
    .navbar-toggle {
        margin-top: 20px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-family: 'Open Sans Condensed', sans-serif;
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.header {
    background: url('../img/img1.jpg');
    background-size: cover;
    padding: 75px 0;

    @media (max-width: 990px) {
        background-position: 100% 0;
    }
    @media (max-width: 767px) {
        background-position: 80% 0;
    }
    @media (max-width: 550px) {
        background-position: 70% 0;
    }
}

.headline {
    font-family: 'Shrikhand', sans-serif;
    color: $primary;
    -webkit-text-stroke-color: $wht;
    -webkit-text-stroke-width: 2px;
    text-shadow: 2px 2px 5px $blk;
    text-align: center;
    margin-top: -20px;
}

.color {
    background: linear-gradient(
        rgba(237,107,93,0.2),
        rgba(237,107,93,0.3)
        ), url('../img/img2.jpg');
    background-position: center center;
    padding: 40px 0;
    background-size: cover;

    @media (max-width: 1200px) {
        padding: 20px 0;
    }

    @media (max-width: 990px) {
        background-position: 25% 0;
    }

    @media (max-width: 550px) {
        background-position: 29% 0;
    }
}

.color2 {
    background: darken($primary, 10%);
}

.subhead {
    font-family: 'Shrikhand', sans-serif;
    color: $wht;

    @media (max-width: 990px) {
        text-align: center;
    }
}

.subTitle {
    font-family: 'Shrikhand', sans-serif;
    color: $wht;
    @include fluid-type($min_width, $max_width, 30px, 40px);

    @media (max-width: 990px) {
        text-align: center;
    }
}

.push {
    margin-top: 24px;

    @media (max-width: 990px) {
        margin-top: 0;
    }
}

.sectTitle {
    font-family: 'Shrikhand', sans-serif;
    color: $wht;
    text-align: center;
    font-size: 40px;
    text-shadow: 2px 2px 2px $primary;
    margin-top: 10px;
}

.featTitle {
    font-family: 'Shrikhand', sans-serif;
    @include fluid-type($min_width, $max_width, 20px, 32px);
    text-align: center;
    color: $primary;
    margin-top: 10px;
}

.featTxt {
    font-family: 'Open Sans Condensed', sans-serif;
    @include fluid-type($min_width, $max_width, 16px, 21px);
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.box {
    padding: 10px 0;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    width: 48%;
    margin-top: 10px;
    height: 270px;
    display: block;
    box-shadow: 2px 2px 6px $primary;
    margin-left: 10px;

    @media (max-width: 1200px) {
        width: 47%;
    }

    @media (max-width: 990px) {
        height: 100%;
        margin: 10px auto;
        width: 100%;
    }
}

i {
    color: darken($primary, 20%);
}

.title-container {
    z-index:1 !important;
    position: absolute;
    margin-top: 350px;
    width:100%;
    text-align:center;
    color:#fff;

    @media (max-width: 990px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 767px) {
        margin-top: 300px;
    }
}

/* FOR IE8 */
video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;

    @media (max-width: 767px) {
        width: auto;
    }
}
/* END IE8*/

.videoBox {
    position: relative;
    width: 100%;
    height: 850px;
    border-bottom: 10px solid $primary;
    overflow: hidden;

    @media (max-width: 767px) {
        height: 800px;
    }
}

.video-overlay {
 position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    transition: all 1s ease;
    

    h1 {
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translateX(-50%) translateY(-50%);
     text-transform: uppercase;
     text-align: center;
    }
}

.pad {
    padding: 150px 0;
}

.pad2 {
    padding: 40px 0;
}

.outroTitle {
    font-family: 'Shrikhand', sans-serif;
    color: $primary;
    padding-top: 150px;
    -webkit-text-stroke-color: $wht;
    -webkit-text-stroke-width: 2px;
    text-shadow: 2px 2px 5px $blk;
    text-align: center;
    @include fluid-type($min_width, $max_width, 40px, 85px);
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.text {
    font-family: 'Open Sans Condensed', sans-serif;
    color: $wht;

    @media (max-width: 990px) {
        text-align: center;
    }
}

.center {
    display: block;
    margin: 0 auto;
}